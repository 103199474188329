
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonCheckbox,
  IonListHeader,
  IonButtons,
  modalController,
} from '@ionic/vue';

import { defineComponent, computed, ref } from 'vue';
import { personCircle, search } from 'ionicons/icons';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Modal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonCheckbox,
    IonList,
    IonButton,
    IonButtons,
    IonLabel,
    IonListHeader,
  },
  setup() {
    const store = useStore();
    const tracks = ref<any>([]);

    const agenda = computed(() => {
      const ids = store.state.agenda.map((session: any) => session.session_id);
      const filtered = store.state.agenda.filter(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/camelcase
        ({ session_id }, index) => !ids.includes(session_id, index + 1)
      );
      return filtered.sort((a: any, b: any) =>
        (a.sessionName || '').localeCompare(b.sessionName || '')
      );
    });

    const handleCancel = () => {
      modalController.dismiss();
    };

    const handleFilter = () => {
      const includedAgendaWithID = agenda.value
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        .filter((track) => track.isChecked);
      // .map((c) => c);
      modalController.dismiss(includedAgendaWithID);
    };

    const onDataCheckBoxChange = (item: any, e: any) => {
      const foundIndex = agenda.value.findIndex(
        (track: any) => track.id == item.id
      );
      agenda.value[foundIndex].isChecked = e.detail.checked;
    };

    return {
      handleCancel,
      handleFilter,
      onDataCheckBoxChange,
      personCircle,
      tracks,
      agenda,
      search,
    };
  },
});
