
import { defineComponent, computed, ref, watchEffect } from 'vue';
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonToolbar,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonSearchbar,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRippleEffect,
  modalController,
  IonSkeletonText,
} from '@ionic/vue';
import { addCircleSharp, trashOutline } from 'ionicons/icons';
import HeaderMain from '@/components/shared/HeaderMain.vue';
import AgendaFilterModal from '@/components/AgendaFilterModal.vue';
import { useStore } from 'vuex';
import { Agenda } from '@/types/interface';
import { fireBaseTime } from '@/helpers/formatDate';
import { useRouter } from 'vue-router';
import { openToast } from '@/helpers/toast';
import bizvento from '@/services/bizvento';
import EmptyState from '@/components/shared/EmptyState.vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonIcon,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonRippleEffect,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonSearchbar,
    HeaderMain,
    EmptyState,
    IonSkeletonText,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const search = ref<string>('');
    const agendaSegmentVal = ref<string>('all');
    const agenda = ref<Agenda[]>(
      store.state.agenda.sort(
        (a: Agenda, b: Agenda) => a.start_date.seconds - b.start_date.seconds
      )
    );

    const searchAgenda = computed(() => {
      return agenda.value.filter((agnd) => {
        return agnd.name.toLowerCase().match(new RegExp(search.value, 'i'));
      });
    });

    const favouriteAgenda = computed((): Agenda[] => {
      const favouriteIds = store.state.favouriteAgenda.map(
        (item: Agenda) => item.id
      );
      return searchAgenda.value.filter(
        (item: Agenda) => favouriteIds.indexOf(item.id) > -1
      );
    });

    const addToFavourite = async (agenda: Agenda): Promise<void> => {
      const inFavourite = favouriteAgenda.value.find(
        (fav) => fav.id === agenda.id
      );

      try {
        if (!inFavourite) {
          store.commit('SET_FAVOURITE_AGENDA', agenda);
          openToast(
            'Agenda has been added to favourites list',
            2000,
            'bottom',
            'success'
          );
          await bizvento.user.createFavourite(
            process.env.VUE_APP_EVENT_ID,
            store.state.user.uid,
            String(agenda.id),
            'agenda'
          );
        } else {
          openToast(
            'Agenda already in favourite tab',
            2000,
            'bottom',
            'danger'
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const removeFromFavourite = async (agenda: Agenda): Promise<void> => {
      try {
        store.commit('REMOVE_FAVOURITE_AGENDA', agenda);
        openToast(
          'Agenda has been removed from favourites list',
          2000,
          'bottom',
          'danger'
        );
        await bizvento.user.deleteFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(agenda.id)
        );
      } catch (error) {
        console.log(error);
      }
    };

    const handleFilterModal = async () => {
      const modal = await modalController.create({
        component: AgendaFilterModal,
        cssClass: 'my-modal-class',
        keyboardClose: true,
        swipeToClose: true,
        mode: 'ios',
      });
      await modal.present();

      const { data } = await modal.onWillDismiss();
      if (data) {
        const filteredAgenda = store.state.agenda.filter((elem: Agenda) =>
          data.find((elem2: any) => elem.colour === elem2.colour)
        );
        agenda.value = filteredAgenda;
      }
    };

    watchEffect(
      () =>
        (agenda.value = store.state.agenda.sort(
          (a: Agenda, b: Agenda) => a.start_date.seconds - b.start_date.seconds
        ))
    );

    return {
      handleFilterModal,
      fireBaseTime,
      addToFavourite,
      removeFromFavourite,
      search,
      agendaSegmentVal,
      favouriteAgenda,
      searchAgenda,
      router,
      addCircleSharp,
      trashOutline,
      agenda,
    };
  },
});
